<template>
  <div class="apitoken-container" style="padding: 30px 0">
    <el-form ref="form" class="geology-form" label-width="120px">
      <el-row>
        <el-col :span="23" :offset="1">
          <el-form-item label="接口地址:" style="margin-bottom: 0">
            {{ apiTokenDetail.ApiUrl }}
          </el-form-item>
        </el-col>
        <el-col :span="23" :offset="1">
          <el-form-item label="Token:" style="margin-bottom: 0">
            {{ apiTokenDetail.Token }}
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="1">
          <el-form-item label="IP白名单:">
            <el-input
              style="margin-top: 10px"
              type="textarea"
              :rows="5"
              v-model="LimiteIp"
              placeholder="请输入IP白名单，一行一个"
              resize="none"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="23" :offset="1">
          <el-form-item>
            <el-button type="primary" size="medium" @click="onSubmit"
              >立即保存</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import { getApiTokenDetail, editApiToken } from "@/api/api";
export default {
  data() {
    return {
      LimiteIp: "",
      apiTokenDetail: "",
    };
  },
  methods: {
    onSubmit() {
      let LimiteIpStr = this.LimiteIp.replace(/[\n]/g, ",");
      this.apiTokenDetail.LimiteIp = LimiteIpStr;
      editApiToken(this.apiTokenDetail)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success("保存成功!");
          } else {
            this.$message.error("保存失败!" + res.msg);
          }
        })
        .catch((errmsg) => {
          this.$message.error("保存失败!" + errmsg);
        });
    },
  },
  created() {
    getApiTokenDetail().then((res) => {
      if (res.code == 0) {
        this.apiTokenDetail = res.data;
        this.LimiteIp = res.data.LimiteIp
          ? res.data.LimiteIp.replace(/,/g, '\n')
          : "";
      }
    });
  },
};
</script>